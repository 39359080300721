import { Props, Playground } from 'docz';
import { HoppinDesignProvider } from "../../../../../src/components/HoppinDesignProvider";
import { Button } from "../../../../../src/components/Button";
import * as React from 'react';
export default {
  Props,
  Playground,
  HoppinDesignProvider,
  Button,
  React
};