import { Props, Playground } from 'docz';
import { Heading } from "../../../../../src/components/Heading";
import { Tag } from "../../../../../src/components/Tag";
import * as React from 'react';
export default {
  Props,
  Playground,
  Heading,
  Tag,
  React
};