import { Props, Playground } from 'docz';
import { Paragraph } from "../../../../../src/components/Paragraph";
import { Box } from "../../../../../src/components/Box";
import { Link, LinkStyled, LinkUnstyled } from "../../../../../src/components/Link";
import * as React from 'react';
export default {
  Props,
  Playground,
  Paragraph,
  Box,
  Link,
  LinkStyled,
  LinkUnstyled,
  React
};