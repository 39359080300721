import Tile from "../../../../../src/components/Image/Tile";
import Avatar from "../../../../../src/components/Image/Avatar";
import { Box } from "../../../../../src/components/Box";
import { Props, Playground } from 'docz';
import * as React from 'react';
export default {
  Tile,
  Avatar,
  Box,
  Props,
  Playground,
  React
};