import { SpaceSwatches, RadiusSwatches } from "../../../../../src/docz-components/Swatches";
import { Flex } from "../../../../../src/components/Flex";
import { space, containerWidths, radii, zIndices } from "../../../../../src/tokens/space";
import * as React from 'react';
export default {
  SpaceSwatches,
  RadiusSwatches,
  Flex,
  space,
  containerWidths,
  radii,
  zIndices,
  React
};