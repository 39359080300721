const logo = {
  shadower:
    'https://storage.googleapis.com/hoppin-platform/ASSETS_LIVE/hoppin-mint-362x120.png',
  whiteout:
    'https://storage.googleapis.com/hoppin-platform/ASSETS_LIVE/hoppin_logo_white_600x198.png',
  host:
    'https://storage.googleapis.com/hoppin-platform/ASSETS_LIVE/hoppin-pink-644x213.png',
};
logo.primary = logo.shadower;

const icon = {
  shadower:
    'https://storage.googleapis.com/hoppin-platform/ASSETS_LIVE/icon-mint-transparent-446x245.png',
  host:
    'https://storage.googleapis.com/hoppin-platform/ASSETS_LIVE/icon-pink-transparent-446x245.png',
};
icon.primary = icon.shadower;

export { logo, icon };
