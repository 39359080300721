import { FiArrowLeft } from 'react-icons/fi';
import { Props, Playground } from 'docz';
import { Flex } from "../../../../../src/components/Flex";
import { Box } from "../../../../../src/components/Box";
import Button from "../../../../../src/components/Button/Button";
import ButtonGroup from "../../../../../src/components/Button/ButtonGroup";
import ButtonNew from "../../../../../src/components/Button/ButtonNew";
import ButtonSelect from "../../../../../src/components/Button/ButtonSelect";
import * as React from 'react';
export default {
  FiArrowLeft,
  Props,
  Playground,
  Flex,
  Box,
  Button,
  ButtonGroup,
  ButtonNew,
  ButtonSelect,
  React
};