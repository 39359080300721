import { useState } from 'react';
import { Props, Playground } from 'docz';
import { FiMail, FiPhone } from 'react-icons/fi';
import { StepTimeline, SteppedForm, StepForm, StepMd } from "../../../../../src/components/SteppedForm";
import { Box } from "../../../../../src/components/Box";
import * as React from 'react';
export default {
  useState,
  Props,
  Playground,
  FiMail,
  FiPhone,
  StepTimeline,
  SteppedForm,
  StepForm,
  StepMd,
  Box,
  React
};