import { Props, Playground } from 'docz';
import { Heading } from "../../../../../src/components/Heading";
import { Caption } from "../../../../../src/components/Caption";
import * as React from 'react';
export default {
  Props,
  Playground,
  Heading,
  Caption,
  React
};