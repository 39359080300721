// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-banner-readme-mdx": () => import("./../../../../src/components/Banner/Readme.mdx" /* webpackChunkName: "component---src-components-banner-readme-mdx" */),
  "component---src-components-box-readme-mdx": () => import("./../../../../src/components/Box/Readme.mdx" /* webpackChunkName: "component---src-components-box-readme-mdx" */),
  "component---src-components-button-readme-mdx": () => import("./../../../../src/components/Button/Readme.mdx" /* webpackChunkName: "component---src-components-button-readme-mdx" */),
  "component---src-components-caption-readme-mdx": () => import("./../../../../src/components/Caption/Readme.mdx" /* webpackChunkName: "component---src-components-caption-readme-mdx" */),
  "component---src-components-card-readme-mdx": () => import("./../../../../src/components/Card/Readme.mdx" /* webpackChunkName: "component---src-components-card-readme-mdx" */),
  "component---src-components-chat-readme-mdx": () => import("./../../../../src/components/Chat/Readme.mdx" /* webpackChunkName: "component---src-components-chat-readme-mdx" */),
  "component---src-components-container-readme-mdx": () => import("./../../../../src/components/Container/Readme.mdx" /* webpackChunkName: "component---src-components-container-readme-mdx" */),
  "component---src-components-expandable-readme-mdx": () => import("./../../../../src/components/Expandable/Readme.mdx" /* webpackChunkName: "component---src-components-expandable-readme-mdx" */),
  "component---src-components-flex-readme-mdx": () => import("./../../../../src/components/Flex/Readme.mdx" /* webpackChunkName: "component---src-components-flex-readme-mdx" */),
  "component---src-components-form-readme-mdx": () => import("./../../../../src/components/Form/Readme.mdx" /* webpackChunkName: "component---src-components-form-readme-mdx" */),
  "component---src-components-heading-readme-mdx": () => import("./../../../../src/components/Heading/Readme.mdx" /* webpackChunkName: "component---src-components-heading-readme-mdx" */),
  "component---src-components-hoppin-design-provider-readme-mdx": () => import("./../../../../src/components/HoppinDesignProvider/Readme.mdx" /* webpackChunkName: "component---src-components-hoppin-design-provider-readme-mdx" */),
  "component---src-components-image-readme-mdx": () => import("./../../../../src/components/Image/Readme.mdx" /* webpackChunkName: "component---src-components-image-readme-mdx" */),
  "component---src-components-lede-readme-mdx": () => import("./../../../../src/components/Lede/Readme.mdx" /* webpackChunkName: "component---src-components-lede-readme-mdx" */),
  "component---src-components-link-readme-mdx": () => import("./../../../../src/components/Link/Readme.mdx" /* webpackChunkName: "component---src-components-link-readme-mdx" */),
  "component---src-components-list-readme-mdx": () => import("./../../../../src/components/List/Readme.mdx" /* webpackChunkName: "component---src-components-list-readme-mdx" */),
  "component---src-components-loader-readme-mdx": () => import("./../../../../src/components/Loader/Readme.mdx" /* webpackChunkName: "component---src-components-loader-readme-mdx" */),
  "component---src-components-logo-readme-mdx": () => import("./../../../../src/components/Logo/Readme.mdx" /* webpackChunkName: "component---src-components-logo-readme-mdx" */),
  "component---src-components-markdown-readme-mdx": () => import("./../../../../src/components/Markdown/Readme.mdx" /* webpackChunkName: "component---src-components-markdown-readme-mdx" */),
  "component---src-components-navigation-readme-mdx": () => import("./../../../../src/components/Navigation/Readme.mdx" /* webpackChunkName: "component---src-components-navigation-readme-mdx" */),
  "component---src-components-overlay-readme-mdx": () => import("./../../../../src/components/Overlay/Readme.mdx" /* webpackChunkName: "component---src-components-overlay-readme-mdx" */),
  "component---src-components-paragraph-readme-mdx": () => import("./../../../../src/components/Paragraph/Readme.mdx" /* webpackChunkName: "component---src-components-paragraph-readme-mdx" */),
  "component---src-components-progress-readme-mdx": () => import("./../../../../src/components/Progress/Readme.mdx" /* webpackChunkName: "component---src-components-progress-readme-mdx" */),
  "component---src-components-stepped-form-readme-mdx": () => import("./../../../../src/components/SteppedForm/Readme.mdx" /* webpackChunkName: "component---src-components-stepped-form-readme-mdx" */),
  "component---src-components-tag-readme-mdx": () => import("./../../../../src/components/Tag/Readme.mdx" /* webpackChunkName: "component---src-components-tag-readme-mdx" */),
  "component---src-components-text-readme-mdx": () => import("./../../../../src/components/Text/Readme.mdx" /* webpackChunkName: "component---src-components-text-readme-mdx" */),
  "component---src-components-timeline-readme-mdx": () => import("./../../../../src/components/Timeline/Readme.mdx" /* webpackChunkName: "component---src-components-timeline-readme-mdx" */),
  "component---src-components-tooltip-tooltip-mdx": () => import("./../../../../src/components/Tooltip/Tooltip.mdx" /* webpackChunkName: "component---src-components-tooltip-tooltip-mdx" */),
  "component---src-index-mdx": () => import("./../../../../src/index.mdx" /* webpackChunkName: "component---src-index-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-reading-list-mdx": () => import("./../../../../src/reading-list.mdx" /* webpackChunkName: "component---src-reading-list-mdx" */),
  "component---src-tokens-colors-mdx": () => import("./../../../../src/tokens/colors.mdx" /* webpackChunkName: "component---src-tokens-colors-mdx" */),
  "component---src-tokens-shadows-mdx": () => import("./../../../../src/tokens/shadows.mdx" /* webpackChunkName: "component---src-tokens-shadows-mdx" */),
  "component---src-tokens-space-mdx": () => import("./../../../../src/tokens/space.mdx" /* webpackChunkName: "component---src-tokens-space-mdx" */)
}

