import { Props, Playground } from 'docz';
import { Flex } from "../../../../../src/components/Flex";
import { Box } from "../../../../../src/components/Box";
import { Loader, CircleLoader, BounceLoader, HeartLoader, Skeleton } from "../../../../../src/components/Loader";
import * as React from 'react';
export default {
  Props,
  Playground,
  Flex,
  Box,
  Loader,
  CircleLoader,
  BounceLoader,
  HeartLoader,
  Skeleton,
  React
};