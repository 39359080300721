module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"remarkPlugins":[[null,{"type":"yaml","marker":"-"}],null],"rehypePlugins":[null,null],"gatsbyRemarkPlugins":[],"defaultLayouts":{"default":"/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js"},"lessBabel":false,"mediaTypes":["text/markdown","text/x-markdown"],"root":"/vercel/path1/.docz"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-theme-docz/gatsby-browser.js'),
      options: {"plugins":[],"themeConfig":{"colors":{"primary":"#44E2D6"},"showPlaygroundEditor":true,"styles":{"root":{"fontFamily":"\"Nunito Sans\", sans-serif","fontSize":2},"Container":{"p":4,"ml":"auto","mr":"auto","maxWidth":"70rem"}}},"src":"./src","gatsbyRoot":null,"themesDir":"src","mdxExtensions":[".md",".mdx"],"docgenConfig":{},"menu":[],"mdPlugins":[],"hastPlugins":[],"ignore":[],"typescript":false,"ts":false,"propsParser":true,"props-parser":true,"debug":false,"native":false,"openBrowser":null,"o":null,"open":null,"open-browser":null,"root":"/vercel/path1/.docz","base":"/","source":"./","gatsby-root":null,"files":"**/*.{md,markdown,mdx}","public":"/public","dest":".docz/dist","d":".docz/dist","editBranch":"master","eb":"master","edit-branch":"master","config":"","title":"Hoppin Design System","description":"Getting consistent design at Hoppin","host":"localhost","port":2000,"p":3000,"separator":"-","paths":{"root":"/vercel/path1","templates":"/vercel/path1/node_modules/docz-core/dist/templates","docz":"/vercel/path1/.docz","cache":"/vercel/path1/.docz/.cache","app":"/vercel/path1/.docz/app","appPackageJson":"/vercel/path1/package.json","appTsConfig":"/vercel/path1/tsconfig.json","gatsbyConfig":"/vercel/path1/gatsby-config.js","gatsbyBrowser":"/vercel/path1/gatsby-browser.js","gatsbyNode":"/vercel/path1/gatsby-node.js","gatsbySSR":"/vercel/path1/gatsby-ssr.js","importsJs":"/vercel/path1/.docz/app/imports.js","rootJs":"/vercel/path1/.docz/app/root.jsx","indexJs":"/vercel/path1/.docz/app/index.jsx","indexHtml":"/vercel/path1/.docz/app/index.html","db":"/vercel/path1/.docz/app/db.json"},"notUseSpecifiers":true},
    }]
