import { Props, Playground } from "docz";
import { Paragraph } from "../../../../../src/components/Paragraph";
import { Text, Emphasize } from "../../../../../src/components/Text";
import * as React from 'react';
export default {
  Props,
  Playground,
  Paragraph,
  Text,
  Emphasize,
  React
};